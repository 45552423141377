/**
 * Do not edit directly
 * Generated on Wed, 15 Jan 2025 14:04:04 GMT
 */

.NordTheme {
  --gdevelop-color-light-blue: #4ab0e4;
  --gdevelop-color-blue: #2c6bf5;
  --gdevelop-color-dark-blue: #3c4698;
  --gdevelop-color-purple: #9100ce;
  --gdevelop-color-contrast-text: #fff;
  --gdevelop-color-selection: #4c92ff; /* OS X selection */
  --gdevelop-classic-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --gdevelop-modern-font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', Helvetica, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --theme-primary-color: #5E81AC;
  --theme-primary-light: #7e9abc;
  --theme-primary-text-contrast-color: #fff;
  --theme-secondary-color: #88C0D0;
  --theme-secondary-text-contrast-color: #2E3440;
  --theme-success-dark: #16CF89; /* Palette/Green/50 */
  --theme-success-color: #45D9A1; /* Palette/Green/40 */
  --theme-success-light: #8BE7C4; /* Palette/Green/30 */
  --theme-success-text-contrast-color: #1D1D26; /* Palette/Grey/100 */
  --theme-warning-dark: #FFA929; /* Palette/Yellow/50 */
  --theme-warning-color: #FFBC57; /* Palette/Yellow/40 */
  --theme-warning-light: #FFD28F; /* Palette/Yellow/30 */
  --theme-warning-text-contrast-color: #1D1D26; /* Palette/Grey/100 */
  --theme-error-dark: #FF5E3B; /* Palette/Red/50 */
  --theme-error-color: #FF8569; /* Palette/Red/40 */
  --theme-error-light: #FFA38F; /* Palette/Red/30 */
  --theme-error-text-contrast-color: #1D1D26; /* Palette/Grey/100 */
  --theme-premium-teal: #3BF7F4; /* Palette/Teal/40 */
  --theme-premium-teal-dark: #09F0EC; /* Palette/Teal/50 */
  --theme-premium-orange: #FFBC57; /* Palette/Yellow/40 */
  --theme-premium-orange-dark: #FFA929; /* Palette/Yellow/50 */
  --theme-premium-text-contrast-color: #1D1D26; /* Palette/Grey/100 */
  --theme-hover-background-color: rgba(255, 255, 255, 0.08);
  --theme-surface-titlebar-background-color: #2E3440;
  --theme-surface-toolbar-background-color: #3B4252;
  --theme-surface-window-background-color: #2E3440;
  --theme-surface-canvas-background-color: #3B4252;
  --theme-surface-alternate-canvas-background-color: #2E3440;
  --theme-surface-alternate-canvas-light-background-color: #343A46;
  --theme-dialog-background-color: #2E3440;
  --theme-dialog-separator-color: #494952; /* Palette/Grey/70 */
  --theme-swipeable-drawer-top-bar-pill-color: #1c1f26;
  --theme-icon-button-selected-color: #3B4252;
  --theme-icon-button-selected-background-color: #88C0D0;
  --theme-link-color: #7EA1CC;
  --theme-link-hover-color: #5E81AC;
  --theme-switch-default-thumb-color: #EBEBED; /* Palette/Grey/10 */
  --theme-switch-default-track-color: #606066; /* Palette/Grey/60 */
  --theme-switch-toggled-thumb-color: #7EA1CC;
  --theme-switch-toggled-track-color: #5E81AC;
  --theme-switch-disabled-thumb-color: #494952; /* Palette/Grey/70 */
  --theme-switch-disabled-track-color: #32323B; /* Palette/Grey/80 */
  --theme-search-bar-default-background-color: #494952; /* Palette/Grey/70 */
  --theme-search-bar-default-text-color: #C5C5C9; /* Palette/Grey/30 */
  --theme-search-bar-hovered-border-color: #7F7F85; /* Palette/Grey/50 */
  --theme-search-bar-focused-text-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-search-bar-focused-border-color: #F6F2FF; /* Palette/Purple/00 */
  --theme-search-bar-disabled-background-color: #32323B; /* Palette/Grey/80 */
  --theme-search-bar-disabled-text-color: #494952; /* Palette/Grey/70 */
  --theme-selection-background-color: #4d6e96;
  --theme-selection-color: #FFFFFF;
  --theme-text-default-color: #ECEFF4;
  --theme-text-secondary-color: #E5E9F0;
  --theme-text-disabled-color: #D8DEE9;
  --theme-text-contrast-color: #E5E9F0;
  --theme-text-highlighted-background-color: rgba(255, 255, 255, 0.3);
  --theme-message-warning-color: #D08770;
  --theme-message-error-color: #BF616A;
  --theme-message-valid-color: #A3BE8C;
  --theme-message-answer-border-color: #5E7B46;
  --theme-message-answer-background-color: #2E3C22;
  --theme-message-empty-shadow-color: #000;
  --theme-message-hot-background-color: #FF5E3B;
  --theme-message-hot-color: #1D1D26;
  --theme-toolbar-separator-color: #D8DEE9;
  --theme-closable-tabs-default-background-color: #434C5E;
  --theme-closable-tabs-default-color: #C5C5C9; /* Palette/Grey/30 */
  --theme-closable-tabs-selected-background-color: #3B4252;
  --theme-closable-tabs-selected-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-closable-tabs-selected-border-color: #5E81AC;
  --theme-list-item-hover-background-color: #39516f;
  --theme-list-item-group-text-color: #ECEFF4;
  --theme-list-item-group-text-deprecated-color: #D8DEE9;
  --theme-list-item-separator-color: #4C566A;
  --theme-list-item-warning-color: #EBCB8B;
  --theme-list-item-error-color: #BF616A;
  --theme-right-icon-color: #fff;
  --theme-palette-black: rgba(110, 42, 42, 1);
  --theme-palette-white: #fff;
  --theme-image-preview-background-filter: invert(1) invert(28%) sepia(19%) saturate(770%) hue-rotate(183deg) brightness(64%) contrast(87%);
  --theme-image-preview-border-color: #4D5362;
  --theme-image-preview-frame-border-color: #ECEFF4;
  --theme-drop-indicator-can-drop-color: #5E81AC;
  --theme-drop-indicator-cannot-drop-color: #BF616A;
  --theme-drop-indicator-border-color: #3B4252;
  --theme-home-separator-color: #32323B;
  --theme-linear-progress-color-complete: #16CF89; /* Palette/Green/50 */
  --theme-linear-progress-color-incomplete: #FFA929; /* Palette/Yellow/50 */
  --theme-example-difficulty-color-simple: #8BE7C4;
  --theme-example-difficulty-color-advanced: #FFD28F;
  --theme-example-difficulty-color-expert: #FFA38F;
  --theme-tabs-text-color: #7F7F85; /* Palette/Grey/50 */
  --theme-tabs-background-color: #343A46;
  --theme-tabs-indicator-color: #A6A6AB; /* Palette/Grey/40 */
  --theme-tabs-separator-color: #606066; /* Palette/Grey/60 */
  --theme-tabs-selected-text-color: #FAFAFA; /* Palette/Grey/00 */
  --theme-tabs-selected-background-color: #494952; /* Palette/Grey/70 */
  --theme-snackbar-border-color: #C5C5C9;
  --theme-snackbar-background-color: #343A46;
  --theme-notification-badge-color: #FF5E3B;
  --theme-text-field-active-error: #FFC2B4; /* Palette/Red/20 */
  --theme-text-field-active-border-color: #D8DEE9;
  --theme-text-field-active-caret-color: #7e9abc;
  --theme-text-field-disabled-color: #7F7F85; /* Palette/Grey/50 */
  --theme-text-field-placeholder-color: #A6A6AB; /* Palette/Grey/40 */
  --theme-text-field-default-background-color: rgba(255, 255, 255, 0.09);
  --theme-text-field-default-error: #FF8569; /* Palette/Red/40 */
  --theme-text-field-hover-border-color: #7F7F85; /* Palette/Grey/50 */
  --theme-text-field-end-adornment-icon-background-color: rgba(221, 209, 255, 0.16);
  --theme-toggle-field-default-slider-color: #EBEBED; /* Palette/Grey/10 */
  --theme-toggle-field-default-background-color: #7F7F85; /* Palette/Grey/50 */
  --theme-toggle-field-active-slider-color: #7EA1CC;
  --theme-toggle-field-active-background-color: #5E81AC;
  --theme-toggle-field-disabled-slider-color: #494952; /* Palette/Grey/70 */
  --theme-toggle-field-disabled-background-color: #32323b; /* Palette/Grey/80 */
  --theme-toggle-field-hover-slider-aura-color: rgba(217, 217, 222, 0.30); /* Palette/Grey/20 and alpha */
  --input-border-bottom-color: #D8DEE9;
  --event-sheet-event-tree-background-color: #2E3440;
  --event-sheet-rst-move-handle-background-color: #81A1C1;
  --event-sheet-rst-move-handle-hover-background-color: #5E81AC;
  --event-sheet-rst-line-background-color: #D8DEE9;
  --event-sheet-rst-row-contents-background-color: #2E3440;
  --event-sheet-rst-row-contents-color: #ECEFF4;
  --event-sheet-selectable-background-color: rgba(0, 0, 0, 0.2);
  --event-sheet-selectable-border: 1px #2E3440 solid;
  --event-sheet-selectable-selected-border: 1px #5E81AC solid;
  --event-sheet-conditions-background-color: #3B4252;
  --event-sheet-conditions-border-color: #3B4252;
  --event-sheet-conditions-border: 1px solid #3B4252;
  --event-sheet-conditions-color: #FFFFFF;
  --event-sheet-conditions-border-right-color: #3B4252;
  --event-sheet-conditions-border-bottom-color: #2E3440;
  --event-sheet-events-highlighted-background-color: #5E5137;
  --event-sheet-events-highlighted-color: #EBCB8B;
  --event-sheet-actions-background-color: #2E3440;
  --event-sheet-actions-color: #FFFFFF;
  --event-sheet-sub-instructions-border-color: #2E3440;
  --event-sheet-sub-instructions-border: 1px solid #2E3440;
  --event-sheet-warning-instruction-background-color: rgba(255, 188, 87, 0.125); /* Palette/Yellow/40 and alpha */
  --event-sheet-instruction-parameter-base-color: #8FBCBB;
  --event-sheet-instruction-parameter-number-color: #A3BE8C;
  --event-sheet-instruction-parameter-object-color: #5E81AC;
  --event-sheet-instruction-parameter-behavior-color: rgb(119, 119, 119);
  --event-sheet-instruction-parameter-operator-color: #B48EAD;
  --event-sheet-instruction-parameter-var-color: #D08770;
  --event-sheet-instruction-parameter-error-color: #BF616A;
  --event-sheet-instruction-parameter-error-background-color: rgba(243, 0, 12, 0.15);
  --event-sheet-instruction-parameter-warning-color: #D08770;
  --event-sheet-drop-indicator-can-drop-border-top-color: #4C566A;
  --event-sheet-drop-indicator-cannot-drop-border-top-color: #BF616A;
  --event-sheet-link-container-background-color: #2E3440;
  --event-sheet-link-container-color: #FFFFFF;
  --event-sheet-link-selectable-link-color: #81A1C1;
  --markdown-blockquote-border-left-color: #D8DEE9;
  --markdown-link-color: #ECEFF4;
  --sound-player-play-button-primary-border-color: #5E81AC;
  --sound-player-play-button-primary-background-color: #5E81AC;
  --sound-player-play-button-primary-color: #FFF;
  --sound-player-play-button-primary-hover-background-color: #7e9abc;
  --sound-player-play-button-primary-hover-border-color: #7e9abc;
  --sound-player-play-button-secondary-border-color: #88C0D0;
  --sound-player-play-button-secondary-background-color: #3B4252;
  --sound-player-play-button-secondary-color: #F5F5F7;
  --sound-player-play-button-secondary-hover-background-color: #2E3440;
  --sound-player-play-button-secondary-hover-border-color: #88C0D0;
  --sound-player-wave-color: #9979F1;
  --sound-player-progress-color: #7046EC;
  --mosaic-title-color: #ECEFF4;
  --mosaic-layout-border-color: #888888;
  --mosaic-layout-background-color: #3B4252;
  --mosaic-toolbar-background-color: #81A1C1;
  --mosaic-toolbar-border-color-hover: #5E81AC;
  --table-border-color: #3B4252;
  --table-text-color: #ECEFF4;
  --table-text-color-header: #D8DEE9;
  --table-row-odd-background-color: #3B4252;
  --table-row-even-background-color: #2E3440;
  --table-row-selected-background-color: #5E81AC;
  --table-row-selected-color: #2E3440;
}
